body {
  background-color: #000000;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'IBM Plex Mono', 'Courier New', monospace;
}

.App {
  text-align: left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #000080;
  position: relative;
  text-shadow: 0px 0px 1px #ffffff;
  padding: 8px;
  box-sizing: border-box;
  border: 12px solid #000000;
}

.bios-header {
  background-color: #00AAAA;
  color: #000000;
  text-align: center;
  padding: 5px 0;
  font-weight: bold;
  border-bottom: 1px solid #000000;
  text-shadow: none;
}

.bios-menu {
  display: flex;
  background-color: #000080;
  border-bottom: 1px solid #FFFFFF;
}

.bios-menu-item {
  background-color: #000080;
  color: #FFFFFF;
  padding: 5px 15px;
  border-right: 1px solid #FFFFFF;
  cursor: pointer;
  font-weight: bold;
}

.bios-menu-item.active {
  background-color: #0000FF;
}

.bios-content {
  display: flex;
  flex: 1;
  font-size: 14px;
}

.bios-main {
  flex: 3;
  background-color: #000080;
  color: #FFFFFF;
  padding: 10px;
  border-right: 1px solid #FFFFFF;
}

.bios-help {
  flex: 1;
  background-color: #000080;
  color: #FFFFFF;
  padding: 10px;
  border-left: 1px solid #000000;
}

.bios-help-title {
  text-align: center;
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 8px;
  margin-bottom: 12px;
  font-weight: bold;
}

.bios-row {
  display: flex;
  margin: 8px 0;
  align-items: center;
}

.bios-label {
  flex: 1;
  color: #FFFFFF;
  font-weight: bold;
  white-space: nowrap;
}

.bios-value {
  flex: 1;
  text-align: right;
  color: #FFFFFF;
  white-space: nowrap;
}

.highlight {
  color: #FFFF00 !important;
}

.bios-separator {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 10px 0;
}

.personal-info {
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 10px;
}

.bios-footer {
  background-color: #000080;
  border-top: 1px solid #FFFFFF;
  display: flex;
  padding: 5px 0;
  margin-top: 10px;
}

.footer-item {
  color: #FFFFFF;
  margin: 0 15px;
}

.footer-key {
  color: #FFFF00;
  font-weight: bold;
}

.language-toggle {
  position: absolute;
  top: 6px;
  right: 10px;
  z-index: 100;
}

.language-toggle button {
  background: #000080;
  border: 1px solid white;
  color: white;
  padding: 3px 8px;
  cursor: pointer;
  font-family: 'IBM Plex Mono', 'Courier New', monospace;
  font-size: 0.8rem;
}

.language-toggle button:hover {
  background: #0000AA;
}

.section-heading {
  background-color: #00AAAA;
  color: #000000;
  padding: 3px 5px;
  margin: 12px 0 8px 0;
  font-weight: bold;
  text-shadow: none;
}

.bios-cursor {
  display: inline-block;
  width: 8px;
  height: 14px;
  background-color: #FFFFFF;
  margin-left: 5px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 49% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}

.system-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.system-table td {
  padding: 3px 5px;
}

.system-table td:first-child {
  color: #FFFFFF;
  white-space: nowrap;
}

.system-table td:last-child {
  text-align: right;
  color: #FFFF00;
}

@media (max-width: 768px) {
  .bios-content {
    flex-direction: column;
  }
  
  .bios-main, .bios-help {
    width: 100%;
  }
  
  .bios-menu {
    flex-wrap: wrap;
  }
  
  .bios-menu-item {
    flex: 1;
    text-align: center;
    padding: 5px 5px;
    min-width: 70px;
  }
  
  .bios-row {
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0;
  }
  
  .bios-value {
    text-align: left;
    margin-top: 3px;
  }
  
  .bios-footer {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footer-item {
    margin: 5px;
    font-size: 14px;
  }
}
